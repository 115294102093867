<template>
  <div>
    <v-row align="center" justify="space-between">
      <v-col cols="12" md="6">
        <v-btn
          :disabled="!selected.length || selected.some(el => !el.shipped_date)"
          @click="markShipped"
          :loading="markLoading"
          color="primary"
        >
          MARK AS SHIPPED {{ selected.length }}
        </v-btn>
      </v-col>

      <v-col cols="12" md="6" class="d-flex justify-start justify-md-end">
        <v-radio-group row v-model="shipped">
          <v-radio label="Not Shipped" :value="false" />
          <v-radio label="Shipped" :value="true" />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert dense v-if="selected.some(el => !el.shipped_date)" type="warning">You need to select Shipped Date on all selected items</v-alert>
      </v-col>
      <v-col>
        <v-data-table
          show-select
          @click:row="handleRowClick"
          :headers="headers"
          :loading="queueLoading"
          :items="appeals"
          v-model="selected"
          class="elevation-1"
        >
          <template #item.shipped_date="{ item }">
            <v-row>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(item.shipped_date)"
                      label="Date"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      :disabled="!selected.some(el => el.id === item.id)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.shipped_date"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import AppealsService from "@/services/appeals.service";

  export default {
    name: "AppealLetterQueue",
    data() {
      return {
        queueLoading: false,
        shipped: false,
        appeals: [],
        selected: [],
        headers: [
          {
            text: "Client",
            value: "trade_name",
          },
          {
            text: "Legal Name",
            value: "legal_name",
          },
          {
            text: "Client ID",
            value: "id",
          },
          {
            text: "Denial Letter Uploaded Date",
            value: "upload_date",
          },
          {
            text: "Appeal Letter Shipped Date",
            value: 'shipped_date',
          }
        ],
        markLoading: false,
      };
    },
    methods: {
      async getQueue(filter = 0) {
        this.queueLoading = true;

        try {
          this.appeals = await AppealsService.getAppealsReport(filter);
        } finally {
          this.queueLoading = false;
        }
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      handleRowClick(row) {
        this.$router.push(`/clients/${row.id}/appeals`)
      },
      async markShipped() {
        this.markLoading = true

        try {
          for (let i = 0; i < this.selected.length; i++) {
            const item = this.selected[i]

            const payload = {
              shipped_date: item.shipped_date
            }

            await AppealsService.updateAppeal(item.appeal_id, payload)
          }
        } finally {
          this.markLoading = false
        }
      }
    },
    created() {
      this.getQueue();
    },
    watch: {
      shipped: function(val) {
        this.selected = []
        if (val)
          this.getQueue(1);
        else
          this.getQueue();
      },
    },
  };
</script>